import React, { Component } from "react";
import logo from "./assets/img/logo.png";
import img1 from "./assets/img/caroussel/1.png";
import img2 from "./assets/img/caroussel/2.jpg";
import img3 from "./assets/img/caroussel/3.png";
import { Caroussel } from "./components/caroussel/index";
import { AppLink } from "./components/appLinks/index";

import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div>
          <div className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <h1>Join the ground martial platform!</h1>
          <p className="App-description">
            GroundLab is a totally free application to share and learn ground fighting techniques. Athletes coming from BJJ (Brazilian
            jiu jitsu), judo, grappling, wrestling, luta livre or any other ground fighting sports are already learning and sharing
            their techniques.
          </p>
          <p>
            We already have more than <strong>1000 techniques</strong> !
          </p>
          <p>A website is coming soon...</p>

          <div style={{ marginTop: 24, marginBottom: 20 }}>
            <AppLink />
          </div>

          <Caroussel />

          <div style={{ marginTop: 60, marginBottom: 15 }}>
            <div
              className="fb-like"
              data-href="https://www.facebook.com/pg/GroundLabApp/"
              data-layout="standard"
              data-action="like"
              data-size="small"
              data-show-faces="true"
              data-share="true"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
