//import liraries
import React, { Component } from "react";
import ItemsCarousel from "react-items-carousel";
import ImageZoom from "react-medium-image-zoom";

import Slider from "react-slick";

import subscription from "../../assets/img/caroussel/subscriptions.jpg";
import subscriptionsmall from "../../assets/img/caroussel/subscriptions - Copy.jpg";

import tagsResult from "../../assets/img/caroussel/tags result.jpg";
import tagsResultsmall from "../../assets/img/caroussel/tags result - Copy.jpg";

import upload from "../../assets/img/caroussel/upload.jpg";
import uploadsmall from "../../assets/img/caroussel/upload - Copy.jpg";

import favoris from "../../assets/img/caroussel/favoris.jpg";
import favorissmall from "../../assets/img/caroussel/favoris - Copy.jpg";

import comment from "../../assets/img/caroussel/comment.jpg";
import commentsmall from "../../assets/img/caroussel/comment - Copy.jpg";

import tagsCombination from "../../assets/img/caroussel/tags combination.jpg";
import tagsCombinationsmall from "../../assets/img/caroussel/tags combination - Copy.jpg";

import browse from "../../assets/img/caroussel/browse.jpg";
import browseSmall from "../../assets/img/caroussel/browse - Copy.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//for the zoom react-medium-image-zoom

// create a component
export class Caroussel extends Component {
  createElement(img: any, smallImg: any, legend: String) {
    return (
      <div className="slide">
        <p style={{ height: 35 }}>{legend}</p>
        <ImageZoom
          shouldReplaceImage={false}
          image={{
            src: smallImg,
            alt: legend,
            className: "slide"
            //style: { width: "50em" }
            //style: { border-style: "solid" }
          }}
          zoomImage={{
            src: img,
            alt: legend
          }}
        />
      </div>
    );
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      initialSlide: 0,
      nextArrow: <img alt="Go right" className="Arrow" src={require("../../assets/img/caroussel/chevron-right.png")} />,
      prevArrow: <img alt="Go left" className="Arrow" src={require("../../assets/img/caroussel/chevron-left.png")} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    return (
      <div className="Caroussel">
        <Slider {...settings}>
          {this.createElement(browse, browseSmall, "Browse among 1000 techniques")}
          {this.createElement(subscription, subscriptionsmall, "Follow your favourite athletes")}
          {this.createElement(tagsResult, tagsResultsmall, "Find suitable tags")}
          {this.createElement(tagsCombination, tagsCombinationsmall, "Combine several tags")}
          {this.createElement(upload, uploadsmall, "Share your video with friends")}
          {this.createElement(favoris, favorissmall, "Organise your training")}
          {this.createElement(comment, commentsmall, "Comment your friend's videos")}
          {/* <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
          <div>
            <h3>7</h3>
          </div>
          <div>
            <h3>8</h3>
          </div> */}
        </Slider>
      </div>
    );

    // return (
    //   <ItemsCarousel
    //     // Placeholder configurations
    //     //  enablePlaceholder
    //     // numberOfPlaceholderItems={3}
    //     //minimumPlaceholderTime={1000}
    //     //        placeholderItem={<div style={{ height: 300, background: "#900" }}>Placeholder</div>}

    //     // Carousel configurations
    //     numberOfCards={3}
    //     gutter={10}
    //     showSlither={true}
    //     firstAndLastGutter={true}
    //     freeScrolling={false}
    //     // Active item configurations
    //     requestToChangeActive={this.changeActiveItem}
    //     activeItemIndex={activeItemIndex}
    //     activePosition={"center"}
    //     chevronWidth={55}
    //     leftChevron={<img alt="Go left" src={require("../../assets/img/caroussel/chevron-left.png")} />}
    //     rightChevron={<img alt="Go right  " src={require("../../assets/img/caroussel/chevron-right.png")} />}
    //     outsideChevron={true}
    //   >
    //     {children}
    //   </ItemsCarousel>
    // );
  }
}
