//import liraries
import React, { Component } from "react";
import googlestoreImg from "../../assets/img/googlestore.png";

// create a component
export class AppLink extends Component {
  render() {
    return (
      <div className="AppLink">
        <a href="https://play.google.com/store/apps/details?id=com.GroundLab">
          <img alt="Get it on Google Play" src={googlestoreImg} className="Img" />
        </a>

        <a href="https://itunes.apple.com/gb/app/groundlab-bjj-and-ground-art/id1457442761?mt=8">
          <img
            className="Img"
            alt="Get it on App Store"
            src="https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2011-08-09&kind=iossoftware&bubble=ios_apps no-repeat;"
          />
        </a>
      </div>
    );
  }
}
